.app-log-summary {
  border-top: 1px solid $govuk-border-colour;
  padding-top: govuk-spacing(3);
}

.app-log-summary__header {
  display: flex;
  margin-bottom: govuk-spacing(2);
  align-items: center;
}

.app-log-summary__title {
  margin: auto govuk-spacing(3) auto 0;
  white-space: nowrap;
}

@include govuk-media-query(tablet) {
  .app-log-summary__footer {
    text-align: right;
  }

  .app-log-summary__footer--actor {
    display: block;
  }

  .app-log-summary__details {
    word-break: break-all;
    margin-top: auto;
    margin-bottom: auto;
  }
}
