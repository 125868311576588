.grouped-rows td {
  border-top: none;
  border-bottom: none;
}

.grouped-rows.first-row td {
  border-top: 1px solid #b1b4b6;
}

.grouped-rows.last-row td,
.grouped-rows .grouped-multirow-cell {
  border-bottom: 1px solid #b1b4b6;
}

.no-bottom-border,
.no-bottom-border > tbody > tr:last-of-type td {
  border-bottom: none;
}

.text-normal-break {
  white-space: normal;
  word-break: break-all;
}
