.app-table-group {
  overflow-x: auto;
  overflow-y: hidden;
  margin: govuk-spacing(-3) govuk-spacing(-3) govuk-spacing(3);
  padding: govuk-spacing(3);
  scrollbar-color: $govuk-text-colour govuk-colour("light-grey");

  .govuk-table {
    margin-bottom: -1px;
  }

  &::-webkit-scrollbar {
    height: govuk-spacing(1);
    width: govuk-spacing(1);
  }

  &::-webkit-scrollbar-thumb {
    background: $govuk-text-colour;
  }

  &::-webkit-scrollbar-track {
    background: govuk-colour("light-grey");
  }
}

.scheme-name-cell {
  overflow-wrap: break-word;
  word-break: break-word;
}

.app-table-group:focus {
  box-shadow: 0 0 0 #{$govuk-focus-width * 2} $govuk-focus-colour;
  outline: $govuk-focus-width solid govuk-colour("black");
}

.app-table-group:focus:not(:focus-visible) {
  box-shadow: none;
  outline: none;
}

.app-table-group:focus-visible {
  box-shadow: 0 0 0 #{$govuk-focus-width * 2} $govuk-focus-colour;
  outline: $govuk-focus-width solid govuk-colour("black");
}
