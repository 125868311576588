// Ensure the autocomplete uses the correct typeface
.autocomplete__wrapper {
  font-family: $govuk-font-family;
}

.autocomplete__input {
  font-family: inherit;
  background-image: url("data:image/svg+xml,%3Csvg class='app-search__icon' width='20' height='20' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg' aria-hidden='true' focusable='false'%3E%3Ccircle cx='12.0161' cy='11.0161' r='8.51613' stroke='currentColor' stroke-width='3'%3E%3C/circle%3E%3Cline x1='17.8668' y1='17.3587' x2='26.4475' y2='25.9393' stroke='currentColor' stroke-width='3'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1em 1em;
  background-position: 7px center;
  text-indent: govuk-spacing(6);
}

.autocomplete__option__append {
  font-weight: bold;
}

.autocomplete__hint {
  font-family: inherit;
  text-indent: govuk-spacing(6);
}

.autocomplete__option__hint {
  display: block;
  color: $govuk-secondary-text-colour;

  .autocomplete__option--focused &,
  .autocomplete__option:hover & {
    color: govuk-colour("white");
  }
}

// Style the autocomplete if there’s an error
.govuk-form-group--error {
  .autocomplete__input {
    border-color: $govuk-error-colour;
  }

  .autocomplete__input--focused {
    border-color: $govuk-input-border-colour;
  }
}

.autocomplete__dropdown-arrow-down {
  // Ensure dropdown arrow can be clicked
  // https://github.com/alphagov/accessible-autocomplete/issues/202
  pointer-events: none;
  // Ensure dropdown arrow can be seen
  z-index: 0;
}
