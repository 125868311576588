.app-document-list {
  margin-bottom: govuk-spacing(6);
}

.app-document-list__item {
  margin-bottom: govuk-spacing(3);

  &:last-child {
    margin-bottom: 0;
  }
}

.app-document-list__item-title {
  @include govuk-font($size: 16);
  margin: 0 0 govuk-spacing(1);
}

.app-document-list__item-description {
  @include govuk-font($size: 16);
  margin: govuk-spacing(1) 0;
}

.app-document-list__item-metadata {
  @include govuk-font($size: 16);
  color: $govuk-secondary-text-colour;
  margin: 0;
  padding: 0;
}
