.app-sub-navigation {
  @include govuk-font(19, $weight: bold);
  @include govuk-responsive-margin(6, "bottom");
}

.app-sub-navigation__list {
  @include govuk-clearfix;
  left: govuk-spacing(-3);
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  right: govuk-spacing(-3);
  width: calc(100% + #{govuk-spacing(6)});

  @include govuk-media-query($from: tablet) {
    box-shadow: inset 0 -1px 0 $govuk-border-colour;
  }
}

.app-sub-navigation__item {
  box-sizing: border-box;
  display: block;
  line-height: 40px;
  height: 40px;
  padding: 0 govuk-spacing(3);

  @include govuk-media-query($from: tablet) {
    box-shadow: none;
    display: block;
    float: left;
    line-height: 50px;
    height: 50px;
    padding: 0 govuk-spacing(3);
    position: relative;
  }
}

.app-sub-navigation__item--current {
  @include govuk-media-query($until: tablet) {
    border-left: $govuk-border-width-narrow solid $govuk-link-colour;
    padding-left: 11px;
  }

  @include govuk-media-query($from: tablet) {
    border-bottom: $govuk-border-width-narrow solid $govuk-link-colour;
    padding-left: govuk-spacing(3);
  }

  &:hover {
    border-color: $govuk-link-hover-colour;
  }

  &:active {
    border-color: $govuk-link-active-colour;
  }
}

.app-sub-navigation__link {
  @include govuk-link-common;
  @include govuk-link-style-no-visited-state;
  @include govuk-link-style-no-underline;
  @include govuk-typography-weight-bold;
  position: relative;

  // Extend the touch area of the link to the list
  &::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.app-sub-navigation__item--current .app-sub-navigation__link {
  &:hover {
    text-decoration: none;
  }
}
