.app-card {
  @include govuk-responsive-padding(4);
  @include govuk-font($size: 19);
  background-color: govuk-colour("light-grey");
  display: block;
  position: relative;

  > *:last-child {
    margin-bottom: 0;
  }
}
