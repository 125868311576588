@function frontend-font-url($filename) {
  @return url("~assets/fonts/" + $filename);
}

@function frontend-image-url($filename) {
  @return url("~assets/images/" + $filename);
}

$govuk-font-url-function: frontend-font-url;
$govuk-image-url-function: frontend-image-url;
$govuk-global-styles: true;
$govuk-new-link-styles: true;

// Add additional breakpoint named `wide`
$govuk-breakpoints: (
  mobile: 320px,
  tablet: 641px,
  desktop: 769px,
  wide: 921px,
);

@import "govuk-frontend-styles";
@import "govuk-prototype-styles";

@import "accessible-autocomplete";
@import "bulk-uploads";
@import "button";
@import "card";
@import "data_box";
@import "delete-logs-table";
@import "document-list";
@import "errors";
@import "feedback";
@import "filter";
@import "filter-layout";
@import "header";
@import "input";
@import "log";
@import "metadata";
@import "related-navigation";
@import "section-skip-link";
@import "tab";
@import "table-group";
@import "tag";
@import "task-list";
@import "template";
@import "panel";
@import "search";
@import "sub-navigation";
@import "unread-notification";
@import "red-link";
@import "custom-rails-admin";
@import "node_modules/@ministryofjustice/frontend/moj/components/date-picker/date-picker";

// App utilities
.app-\!-colour-muted {
  // stylelint-disable declaration-no-important
  color: $govuk-secondary-text-colour !important;
}

.app-\!-colour-red {
  color: govuk-colour("red") !important;
}

.app-\!-font-tabular {
  @include govuk-font($size: false, $tabular: true);
}

// Overrides
.govuk-button-group {
  align-items: center;
}

.govuk-tag {
  white-space: nowrap;
}

.button_to {
  @include govuk-media-query($until: tablet) {
    width: 100%;
  }
}

.govuk-footer {
  border-top: govuk-spacing(2) solid $govuk-brand-colour;
}

.govuk-notification-banner__content > * {
  max-width: fit-content;
}

.govuk-service-navigation__active-fallback,
.govuk-service-navigation__list {
  font-weight: bold;
}

.govuk-service-navigation__link {
  @include govuk-link-common;
  @include govuk-link-style-no-visited-state;
  @include govuk-link-style-no-underline;
  @include govuk-typography-weight-bold;

  // Extend the touch area of the link to the list
  &::after {
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.govuk-service-navigation__item--active {
  border-bottom-width: 4px;
}

.govuk-service-navigation__item {
  padding-right: 15px;
  padding-left: 15px;
  margin: 0;
}

.govuk-service-navigation__item:not(:last-child) {
  margin-right: 0;
}

.govuk-service-navigation__container {
  left: -15px;
  position: relative;
}
