.app-unread-notification {
  background-color: govuk-colour("blue");
}

.app-unread-notification p {
  color: govuk-colour("white");
}

.app-unread-notification a {
  color: govuk-colour("white");
}
