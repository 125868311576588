.app-data-box-section {
  @include govuk-font($size: 19);
  font-weight: bold;
  white-space: nowrap;
}

.app-data-box-group {
  @include govuk-responsive-margin(4, "top");
}

@media (min-width: 54.0625em) {
  .app-data-box-group-row {
    display: flex;
    justify-content: space-between;
    column-gap: govuk-spacing(4);
    width: 100%;
    min-width: 733.33px;
  }
}

@media (min-width: 54.0625em) {
  .app-data-box__underline {
    min-width: 733.33px;
  }
}

@media (min-width: 54.0625em) {
  .app-data-box-one-half {
    width: 50%;
    float: left;
  }
}

@media (min-width: 54.0625em) {
  .app-data-box-one-half__underline {
    min-width: 733.33px;
  }
}

.app-data-box__upper {
  @include govuk-responsive-margin(2, "bottom");
  @include govuk-responsive-padding(4);

  background-color: govuk-colour("light-grey");
  color: govuk-colour("blue");
}

.app-data-box__lower {
  @include govuk-responsive-margin(4, "bottom");
  @include govuk-responsive-padding(4);

  background-color: govuk-colour("blue");
}

.app-data-box__count {
  font-size: 48px;
  color: govuk-colour("blue");
}
